<template>
  <div class="container" v-loading="loading">
    <el-row v-if="isURLValid">
      <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
        <div class="img-item">
          <img
            v-if="this.imgData"
            :src="this.imgData"
            height="100"
            width="200"
            class="avatar"
          />
          <img
            v-else-if="companyDataLocal.logo"
            :src="companyDataLocal.logo"
            height="100"
            width="150"
            style="border-radius: 5px;"
            class="avatar"
          />
          <img
             v-else-if="appSettings.APPLICATION_TYPE == 'ESIGNS'"
            src="@/assets/img/eSigns Logo Black.svg"
            alt="Nimble Logo"
            class="avatar"
          />
        </div>
        <el-row
          v-if="isFromPreview"
          style="margin-top: 85px; width: 350px; margin-left: 20px"
        >
          <el-skeleton />
          <el-button
            class="signin-btn-for-preview"
            :style="`background: ${loginPageColor} !important;`"
            :disabled="isFromPreview"
            >Sign In</el-button
          >
        </el-row>
        <el-row v-else type="flex" justify="center">
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="22"
            :xs="22"
            style="margin-top: 15% !important"
          >
            <div v-if="isSettedPassword && !isForgotPassword">
              <!-- set password -->
              <el-form ref="form" @submit.native.prevent>
                <h1 class="fs-2 text-dark">Set password</h1>

                <el-form-item label="Enter OTP">
                  <el-input v-model="otp" placeholder="Enter otp"></el-input>
                </el-form-item>
                <span style="color: #5cb85c" v-if="successMessages.length">
                  {{ successMessages }}
                </span>
                <el-form-item label="Set password" prop="password">
                  <el-input
                    v-model="setPassword"
                    placeholder="Set password"
                    :type="passwordType"
                  ></el-input>
                  <el-button
                    type="text"
                    class="password-switch-2"
                    v-if="passwordType == 'password'"
                    @click="passwordType = 'text'"
                    icon="el-icon-view"
                  ></el-button>
                  <el-button
                    type="text"
                    class="password-switch-2"
                    v-if="passwordType != 'password'"
                    @click="passwordType = 'password'"
                  >
                    <img
                      src="@/assets/img/eye-slash.svg"
                      height="15"
                      width="15"
                    />
                  </el-button>
                </el-form-item>
                <el-form-item label="Confirm password" prop="password">
                  <el-input
                    v-model="conformPassword"
                    placeholder="Confirm password"
                    :type="confirmPasswordType"
                  ></el-input>
                  <el-button
                    type="text"
                    class="password-switch-3"
                    v-if="confirmPasswordType == 'password'"
                    @click="confirmPasswordType = 'text'"
                    icon="el-icon-view"
                  ></el-button>
                  <el-button
                    type="text"
                    class="password-switch-3"
                    v-if="confirmPasswordType != 'password'"
                    @click="confirmPasswordType = 'password'"
                  >
                    <img
                      src="@/assets/img/eye-slash.svg"
                      height="15"
                      width="15"
                    />
                  </el-button>
                </el-form-item>
                <span v-if="passwordErrors.length" style="color: red">
                  {{ passwordErrors.join(",") }}
                </span>
                <el-form-item>
                  <el-button
                    class="confirm-btn"
                    :style="`background: ${loginPageColor} !important;`"
                    @click="checkPassword"
                    :disabled="!otp"
                    >Confirm</el-button
                  >
                </el-form-item>
                <div class="nav-link fs-7 text-center">
                  <el-link
                    @click="gotoSetPassword(null)"
                    type="primary"
                    class="no-underline"
                    >Resend verification mail</el-link
                  >
                </div>
              </el-form>
            </div>

            <div v-else-if="isForgotPassword">
              <el-form @submit.native.prevent>
                <div class="nav-link fs-7 text-left mb-3">
                  Already have an account?
                  <el-button
                    type="text"
                    class="fw-medium"
                    style="color: #1989fa"
                    @click="loginPageForLoginLink"
                    >Log In</el-button
                  >
                </div>
                <h1 class="fs-2 text-dark">Forgot password</h1>

                <p class="text-muted opacity-80 fs-7">
                  Forgot your password ? No worries.. Just enter the email you
                  used to signup and we'll send you the OTP code.
                </p>
                <hr />
                <el-form-item label="Email Id*" class="mb-1" prop="email">
                  <el-input
                    type="email"
                    placeholder="Enter email"
                    v-model="forgotPasswordData.email"
                  ></el-input>
                </el-form-item>
                <span style="color: red" v-if="VerificationErrors.length">
                  {{ VerificationErrors }}
                </span>
                <el-form-item class="mt-4">
                  <el-button
                    :style="`background: ${loginPageColor} !important;`"
                    class="send-code-btn"
                    @click="gotoSetPassword(forgotPasswordData.email)"
                    >Send verification code</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <div v-else>
              <h1 class="fs-2 text-dark pb-2">Sign In</h1>
              <p class="text-muted opacity-80 fs-7">
                Greetings!
                <br />Please enter your Credentials:
              </p>
              <el-form
                :model="signin"
                ref="signin"
                class="log-form"
                @submit.native.prevent
              >
                <el-form-item label="Email address" prop="email">
                  <el-input
                    v-model="signin.email"
                    placeholder="Ex: johnwesley@abc.com"
                    :readOnly="isFromPreview || isSettedPassword || isVerified"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Password"
                  class="mb-1"
                  prop="password"
                  v-if="isVerified"
                >
                  <el-input
                    :type="passwordType"
                    placeholder="Enter Password"
                    v-model="signin.password"
                    :readOnly="isFromPreview"
                  ></el-input>
                  <el-button
                    type="text"
                    class="password-switch-2"
                    v-if="passwordType == 'password'"
                    @click="passwordType = 'text'"
                    icon="el-icon-view"
                  ></el-button>
                  <el-button
                    type="text"
                    class="password-switch-2"
                    v-if="passwordType != 'password'"
                    @click="passwordType = 'password'"
                  >
                    <img
                      src="@/assets/img/eye-slash.svg"
                      height="15"
                      width="15"
                    />
                  </el-button>
                </el-form-item>
                <el-form-item prop="type">
                  <el-col :span="11">
                    <el-button
                      type="text"
                      style="margin-left: 139%"
                      :disabled="isFromPreview"
                      @click="goToForgotPassword"
                      >Forgot Password?</el-button
                    >
                  </el-col>
                </el-form-item>
                <span style="color: red" v-if="VerificationErrors.length">
                  {{ VerificationErrors }}
                </span>
                <!-- {{ getErrorCode }} -->
                <el-form-item class="mt-1" v-if="isVerified">
                  <el-button
                    class="signin-btn"
                    :style="`background: ${loginPageColor} !important;`"
                    :disabled="isFromPreview"
                    @click="signinVerification"
                    >Sign In</el-button
                  >
                </el-form-item>
                <el-form-item class="mt-1" v-if="!isVerified">
                  <el-button
                    class="signin-btn"
                    :style="`background: ${loginPageColor} !important;`"
                    @click="validateEmail(email)"
                    >Continue</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col
        :xl="12"
        :lg="12"
        :md="12"
        :sm="24"
        :xs="24"
        class="description-card"
        :style="`background: ${hexConvertedColor} !important;`"
      >
        <el-row type="flex" justify="center">
          <div class="circle-item">
            <div
              class="outer-circle"
              :style="` border: 1px solid ${loginPageColor};`"
            >
              <div
                class="res-circle"
                :style="`background: ${loginPageColor} !important;`"
              >
                <div class="circle-txt">{{ companyDataLocal.name }}</div>
              </div>
            </div>
          </div>
        </el-row>

        <el-row type="flex" justify="center">
          <div class="descript">{{ companyDataLocal.about_company_text }}</div>
        </el-row>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-col :xl="18" :lg="18" :md="12" :sm="24" :xs="24">
        <div class="invalid-url">
          <h3>Invalid URL <i class="el-icon-error"></i></h3>
          <p>Sorry! This company does not exist.</p>
          <p class="highlighted-text">
            Please contact the owner to access the valid URL.
          </p>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      class="plain-header"
    >
      <div class="icon-container">
        <img src="@/assets/img/SyncNewChanges.gif" />
        <p class="text-bold">{{ syncLoadingText }}</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import UserHelper from "@/mixins/UserHelper";
import AppConfig from "@/config/app";
import CryptoJS from "crypto-js";
import { setApplicationSetup } from "@/repo/appSettingRepo";

export default {
  name: "contactLoginPage",
  computed: {
    ...mapGetters("company", ["getContactLoginSlug", "getSingleCompanyLogo"]),
    ...mapGetters("applicationUsers", [
      "getUserVerifyDetails",
      "getUserEmailVerifyDetails",
      "getUserEmailVerificationErrors",
      "getUserSignInVerify",
      "getForgotAndResendVerify",
      "getForgotAndResendVerifyError",
    ]),
    ...mapGetters("auth", [
      "getAuthenticationStatus",
      "getAccountNotVerified",
      "getAccountNotVerifiedEmail",
      "getWorkspaceList",
      "getAuthenticatedUser",
      "getUserType",
      "getErrorCode",
      "getErrorData",
      "getResendVerificationEmail",
      "getActiveContactType",
      "getUserSignInErrors",
    ]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
  },
  mixins: [UserHelper],
  props: ["isFromPreview", "companyData", "imgData", "logo"],
  data() {
    return {
      email: "",
      password: "",
      passwordType: "password",
      confirmPasswordType: "password",
      otp: "",
      setPassword: "",
      conformPassword: "",
      existedSigninEmail: "",
      password_rules: [
        {
          message:
            "Must have one Uppercase(A-Z) letter,one lowercase(a-z) letter ,one number(0-9) and one symbol",
          regex:
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
        },
      ],
      signin: {
        email: "",
        phone: "",
        password: "",
        remember_me: "",
      },
      loginPageColor: "#1B487E",
      bannerColor:"#1B487E",
      hexConvertedColor: "#F4F9FE",
      companyDataLocal: {},
      isURLValid: true,
      isVerified: false,
      isSettedPassword: false,
      isForgotPassword: false,
      passwordErrors: [],
      passwordError: "",
      loading: false,
      VerificationErrors: "",
      successMessages: "",
      contactDetails: null,
      resendDetails: null,
      forgotPasswordData: {
        email: "",
        company: "",
      },
      appSettings: null,
      centerDialogVisible: false,
      syncLoadingText: "Please wait, we are setting things up for you...",
    };
  },
  async created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode == 13) {
        evt.preventDefault();
        if (that.isSettedPassword) {
          that.checkPassword();
        } else if (that.isForgotPassword) {
          that.gotoSetPassword(that.forgotPasswordData.email);
        } else {
          if (that.isVerified) {
            that.signinVerification();
          } else {
            that.validateEmail();
          }
        }
      }
    });
  },
  async mounted() {
    this.appSettings = AppConfig;
    if (this.isFromPreview) {
      this.companyDataLocal = { ...this.companyData };
      this.companyDataLocal.logo = `${AppConfig.S3_BUCKET_URL}/${this.companyData.logo}`;
      this.setColors();
    } else {
      if (!this.$route.query.company) {
        this.isURLValid = false;
        return;
      }
      this.loading = true;
      let params = {
        slug: this.$route.query.company,
      };
      await this.$store.dispatch("company/fetchCompanyBySlug", params);
      if (this.getContactLoginSlug && this.getContactLoginSlug._id) {
        this.companyDataLocal = { ...this.getContactLoginSlug };

        this.companyDataLocal.logo = `${AppConfig.S3_BUCKET_URL}/${this.getContactLoginSlug.logo}`;
        this.setColors();
        this.loading = false;
      } else {
        this.isURLValid = false;
        this.loading = false;
      }
      if (this.$route.query.email) {
        this.loading = true;
        this.signin.email = this.$route.query.email;
        this.contactDetails = {
          email: this.$route.query.email,
        };
        this.gotoSetPassword(this.$route.query.email);
        if (this.$route.query.vc) {
          this.isSettedPassword = true;
          this.isForgotPassword = false;
          this.otp = this.$route.query.vc;
        }
        this.loading = false;
      }
    }
  },
  methods: {
    async checkPassword() {
      //this.loading = true;
      this.passwordError = "";
      this.passwordErrors = [];
      if (this.setPassword) {
        this.password_rules.forEach((rule) => {
          if (!rule.regex.test(this.setPassword)) {
            this.passwordErrors.push(rule.message);
          }
        });
        if (this.setPassword.length && this.conformPassword.length) {
          // this.checkConfirmPassword();
          if (this.setPassword != this.conformPassword) {
            this.passwordErrors.push("Password mismatch!");
            this.isSettedPassword = true;
          } else if (!this.passwordErrors.length) {
            await this.$store.dispatch(
              "applicationUsers/fetchUserEmailVerifyDetails",
              {
                ...(this.contactDetails &&
                  this.contactDetails._id && {
                    contact_id: this.contactDetails._id,
                  }),
                ...(this.signin &&
                  this.signin.email && {
                    email: this.signin.email,
                  }),
                verification_code: this.otp,
                password: this.setPassword,
              }
            );
            if (this.getUserEmailVerifyDetails) {
              //this.loading = false;
              this.$notify({
                title: "Success",
                message: "Password Updated Successfully!",
                type: "success",
              });
              this.isVerified = true;
              this.isSettedPassword = false;
              if (this.contactDetails.email) {
                this.signin.email = this.contactDetails.email;
              }
              if (
                this.getUserEmailVerifyDetails &&
                this.getUserEmailVerifyDetails.data
              ) {
                this.contactDetails = this.getUserEmailVerifyDetails.data;
              }
            } else {
              this.loading = false;
              this.passwordErrors = [];
              this.passwordErrors.push(
                this.getUserEmailVerificationErrors?.message ||
                  "Error while verifying"
              );
            }
          }
        }
      } else {
        this.passwordErrors.push("Password is required");
      }
    },

    async signinVerification() {
      //validation getting Access token and refresh token
      this.loading = true;
      this.VerificationErrors = "";
      await this.$store.dispatch("applicationUsers/fetchSetUserSignInVerify", {
        contact_id: this.contactDetails._id,
        password: this.signin.password,
      });
      if (this.getAuthenticationStatus) {
        this.loading = false;
        this.$notify.success({
          title: "Success",
          message: "Successfully sign in",
        });
        this.$store.commit("errors/reset", null, { root: true });
        this.$store.commit("auth/setErrorCode", "", { root: true });
        this.$store.commit("auth/setErrorData", "", { root: true });
        let redirect_url = "";
        if (this.$route?.query?.redirect_url) {
          let redirectRoute = this.$route?.query?.redirect_url;
          let reb64 = CryptoJS.enc.Hex.parse(redirectRoute);
          let bytes = reb64.toString(CryptoJS.enc.Base64);
          let decrypt = CryptoJS.AES.decrypt(bytes, "esignsRedirect");
          let plain = decrypt.toString(CryptoJS.enc.Utf8);
          let obj = JSON.parse(plain);
          redirect_url = obj.path;
        }
        this.$store.commit("auth/setAppMode", true, {
          root: true,
        });
        this.centerDialogVisible = true;
        await setApplicationSetup();
        this.centerDialogVisible = false;
        await this.checkAndUpdateData(redirect_url);
        // this.$router.push({
        //   name: "application-user-dashboard"
        // });
      } else {
        let message = "Error while sign in";
        if (this.getUserSignInErrors?.message) {
          this.VerificationErrors = this.getUserSignInErrors.message;
          message = this.getUserSignInErrors.message;
        }
        this.$notify.error({
          title: "Error",
          message: message,
        });
        this.loading = false;
      }
    },
    async validateEmail() {
      this.VerificationErrors = "";
      let email = this.signin.email.toLowerCase().trim();
      // regular expression to check email format
      let emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailFormat.test(email)) {
        // email format is not valid
        this.$notify.error({
          title: "Error",
          message: "Invalid Email",
        });
        return false;
      } else {
        this.loading = true;
        // email format is valid
        await this.$store.dispatch("applicationUsers/fetchUserVerifyDetails", {
          email: email,
          company_id: this.companyDataLocal._id,
        });
        if (this.getUserVerifyDetails?.status) {
          // if(this.getUserVerifyDetails.statusCode == "contact_not_found"){
          this.contactDetails = null;
          this.contactDetails = this.getUserVerifyDetails?.data;
          // }
          this.successMessages = "";
          this.loading = false;
          this.$notify({
            title: "Success",
            message: this.getUserVerifyDetails?.message || "Email Verified!",
            type: "success",
          });
          this.successMessages = this.getUserVerifyDetails?.message;
          if (this.getUserVerifyDetails?.statusCode == "account_not_verified") {
            this.isSettedPassword = true;
          } else if (
            this.getUserVerifyDetails?.statusCode == "login_verified"
          ) {
            this.isVerified = true;
          }
        } else {
          this.loading = false;
          this.VerificationErrors = "";
          this.VerificationErrors =
            this.getUserVerifyDetails?.message || "Error while verifying";
        }
        return true;
      }
    },
    goToForgotPassword() {
      this.loading = true;
      this.otp = "";
      this.conformPassword = "";
      this.setPassword = "";
      this.isForgotPassword = true;
      this.loading = false;
    },
    async gotoSetPassword(email) {
      this.VerificationErrors = "";
      let emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!email) {
        email = this.contactDetails?.email;
      }
      if (!emailFormat.test(email)) {
        this.$notify.error({
          title: "Error",
          message: "Invalid Email",
        });
        return false;
      } else {
        this.loading = true;
        await this.$store.dispatch(
          "applicationUsers/fetchForgotAndResendVerify",
          {
            email: email,
            company_id: this.companyDataLocal._id,
          }
        );
        if (this.getForgotAndResendVerify?.data?.status) {
          this.resendDetails = null;
          this.resendDetails = this.getForgotAndResendVerify?.data;
          this.successMessages = "";
          this.contactDetails = this.getForgotAndResendVerify.data;
          this.loading = false;
          this.$notify({
            title: "Success",
            message:
              this.getForgotAndResendVerify?.message ||
              "OTP sent to your email!",
            type: "success",
          });
          this.successMessages = this.getForgotAndResendVerify?.message;
          if (
            this.getForgotAndResendVerify?.statusCode == "account_not_verified"
          ) {
            this.isForgotPassword = true;
          } else if (
            this.getForgotAndResendVerify?.statusCode == "reset_password"
          ) {
            this.isForgotPassword = false;
            this.isSettedPassword = true;
          }
        } else {
          this.loading = false;
          this.VerificationErrors = "";
          this.VerificationErrors =
            this.getForgotAndResendVerifyError?.message ||
            "Error while verifying";
        }
        return true;
      }
    },
    loginPageForLoginLink() {
      this.loading = true;
      this.isVerified = false;
      this.isSettedPassword = false;
      this.isForgotPassword = false;
      this.loading = false;
    },
    setColors() {
      if (this.companyDataLocal?.button?.background) {
        this.hexConvertedColor = this.hexToRgb(
          this.companyDataLocal?.button?.background
        );
      }
      if (this.companyDataLocal?.button?.background) {
        this.loginPageColor = this.companyDataLocal.button.background;
      }
       if (this.companyDataLocal?.banner?.background) {
        this.bannerColor = this.companyDataLocal.banner.background;
      }
    },
    hexToRgb(hex) {
      hex = hex.replace("#", "");
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, 0.1)`;
    },
  },
};
</script>
<style scoped>
.description-card {
  /* background-color: #fff7f1; */
  height: 100vh;
  text-align: center !important;
  overflow: auto;
}
.outer-circle {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  /* background-color: #fff7f1; */
  /* border: 1px solid #ff7200; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  /* background-color: #ff7200; */
  text-align: center;
}
.circle-item {
  margin-top: 23% !important;
}
.login {
  color: #303133;
  font-size: 25px;
  margin-top: 10px;
  margin-left: 200px;
}
.login-des {
  color: #303133;
  font-size: 20px;
  margin-top: 15px;
  margin-left: 100px;
  display: flex;
}
.res-circle {
  /* (A) PERCENTAGE WIDTH & BORDER RADIUS */
  width: 80%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  /* (B) BACKGROUND COLOR */
  background: #ff7200;

  /* (C) NECESSARY TO POSITION TEXT BLOCK */
  line-height: 0;
  position: relative;
}

/* (D) MATCH HEIGHT */
.res-circle::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

/* (E) TEXT BLOCK */
.circle-txt {
  /* (E1) CENTER TEXT IN CIRCLE */
  position: absolute;
  bottom: 50%;
  width: 100%;
  top: 40%;
  /* text-align: center;
   color:#ffffff;
   font-size: 20px;
  /* (E2) THE FONT - NOT REALLY IMPORTANT */
  /* font-family: arial, sans-serif;
  font-weight: bold;  */
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  line-break: auto;
}
.img-item {
  margin-top: 13px;
  margin-left: 10px;
  width: 40% !important;
}
.signin-btn {
  color: #ffffff;
  /* background: #ff7200 !important; */
  width: 100%;
  font-size: 6;
}
.confirm-btn {
  color: #ffffff;
  margin-top: 25px;
  /* background: #ff7200 !important; */
  width: 100%;
  font-size: 5;
}
.send-code-btn {
  color: #ffffff;
  margin-top: 20px;
  /* background: #ff7200 !important; */
  width: 100%;
  font-size: 6;
}
.signin-btn-for-preview {
  color: #ffffff;
  margin-top: 17px;
  width: 100%;
  font-size: 6;
}
.descript {
  margin-top: 13px !important;
  width: 80% !important;
}
.password-switch-2 {
  position: absolute;
  right: 1em;
  top: 3em;
  color: #0b0a0c;
}
.password-switch-3 {
  position: absolute;
  right: 1em;
  top: 3em;
  color: #0b0a0c;
}
.invalid-url {
  margin-top: 250px;
  margin-left: 550px;
  width: 40% !important;
  font-weight: 500;
  font-size: 15px;
  background-color: #f8d7da;
  color: #721c24;
  padding: 30px;
  padding-top: 20px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
}
.highlighted-text {
  font-weight: bold;
}
.no-underline {
  text-decoration: none;
}
.icon-container {
  text-align: center;
}
</style>
